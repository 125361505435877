// Typography
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-style: normal;
    margin-top: 0;
    &:last-child {
        margin-bottom: 0;
    }
}

h1, .h1 {
    font-size: vw(84px);
    font-weight: 800;
    letter-spacing: vw(3.78px);
    text-transform: uppercase;
    @include r(991){
        @include css-lock(37, 63, 575, 991);
    }
}

h2, .h2 {
    font-size: vw(60px);
    font-weight: 800;
    letter-spacing: vw(2.7px);
    text-transform: uppercase;
    @include r(991) {
        @include css-lock(25, 32, 575, 991);
    }
}

h3, .h3 {
    font-size: vw(30px);
    font-weight: 800;
    letter-spacing: vw(1.35px);
    text-transform: uppercase;
    @include r(991) {
        @include css-lock(22, 30, 575, 991);
    }
}

.font-1 {
    font-size: vw(30px);
    line-height: 1.5;

    @include r(991) {
        @include css-lock(18, 24, 575, 991);
    }
}

.font-2 {
    font-size: vw(20px);
    line-height: 1.5;

    @include r(991) {
        @include css-lock(14, 18, 575, 991);
    }
}