@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/normalize";

html,
body {
    height: 100%;
}

html {
    font-size: 62.5%; // 10px = 1rem
    line-height: $main-line-height;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: $main-font;
    font-size: vw(16px);
    line-height: $main-line-height;
    font-weight: $main-font-weight;
    color: $color-main;
    background-color: $color-white;
    @extend %antialias;
    width: 100%;
    position: relative;
    scroll-behavior: smooth;
    @include r(991) {
        @include css-lock(14, 18, 575, 991);
    }
}
*,
*:before,
*:after {
    box-sizing: border-box;
}

img,
svg {
    max-width: 100%;
}

svg {
    fill: currentColor;
    max-height: 100%;
}

button,
a {
    cursor: pointer;
}

strong,
.bold {
    font-weight: 800;
}

.layout {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__header,
    &__footer{
        flex-shrink: 0;
    }
    &__header{
        z-index: 100;
    }
    &__main{
        flex-grow: 1;
        overflow: hidden;
        padding-top: var(--headerHeight);
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: em(15);
    padding-right: em(15);
    width: 100%;
    @include rmin(992){
        max-width: vw(1680px);
    }
    @include r(991){
        padding-left: em(30);
        padding-right: em(30);
    }
}

.row{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-btn{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    width: fit-content;
    padding: em(1) em(15);
    @include r(991){
        padding: em(3) em(15);
    }
    span{
        position: relative;
        z-index: 1;
        margin-right: em(20px);
        transition: color .5s ease-in-out;
    }
    svg{
        position: relative;
        z-index: 1;
        top: 2px;
        transition: transform .5s ease-in-out,
            fill .3s linear;
        @include r(991){
            top: 0;
        }
    }
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        content: '';
        width: 0;
        height: 100%;
        transition: all .5s ease-in-out;
    }

    &.--primary{
        background-color: $color-main;
        color: $color-white;
        svg{
            fill: $color-accent;
            width: vw(16px);
            height: vw(26px);
            @include r(991){
                width: 14px;
                height: 18px;
            }
        }
        &:before{
            background-color: $color-accent;
        }
        &:hover{
            color: $color-main;
            &::before{
                width: 100%;
            }
            svg{
                fill: $color-main;
                transform: translateX(15%);
            }
        }
    }
    &.--accent{
        background-color: $color-accent;
        color: $color-main;
        svg{
            width: vw(34px);
            height: vw(46px);
            @include r(575){
                top: em(1);
                width: em(15);
                height: em(18);
            }
        }
        &:before {
            background-color: $color-main;
        }
        &:hover {
            color: $color-white;
            &::before {
                width: 100%;
            }

            svg {
                fill: $color-white;
                transform: translateX(15%);
            }
        }
    }
}


// Swiper
@import 'swiper/scss';
// @import 'swiper/css/navigation';
@import 'swiper/css/pagination';

// Components
@import "components/index";
@import "pages/index.sass";

// Utils
@import "utils/utils";
