.footer {
    background-color: $color-white;
    padding: em(16) 0;
    font-size: vw(14px);

    @include r(991) {
        @include css-lock(11, 15, 575, 991);
    }

    &__container {

        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;
        padding: 50px var(--headerGap);
        @include r(991) {
            flex-direction: column;
            justify-content: center;
            padding: 20px var(--headerGap);
        }
    }

    @include nav;

    &__list {

        padding: 0;
        align-items: center;
        justify-content: flex-end;

        &-item {

            @include r(991){
                padding-right: 0;
                margin-bottom: 1em;
            }

        }
    }


}
