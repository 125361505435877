.menu-trigger {
    position: absolute;
    z-index: 100;
    top: 15%;
    right: 0;
    width: 27px;
    height: 18px;
    color: currentColor;
    display: flex;
    align-items: center;
    pointer-events: all;

    @include rmin(992) {
        display: none;
    }

    span {
        margin-left: auto;
        display: block;
        width: 100%;
        height: 3px;
        background-color: currentColor;
        position: relative;
        transition: transform $transition-base;
        border-radius: 5px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 27px;
            height: 100%;
            left: 0;
            background-color: currentColor;
            transition: inherit;
            border-radius: inherit;
        }

        &::before {
            top: -7px;
            transform-origin: 100% 35%;
        }

        &::after {
            bottom: -7px;
            transform-origin: 100% 65%;
        }

        .menu-open & {
            background-color: transparent;

            &::before {
                transform: rotate(-45deg);
                background-color: $color-main;
            }

            &::after {
                bottom: -12px;
                transform: rotate(45deg);
                background-color: $color-main;
            }
        }
    }
}