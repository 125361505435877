// Font
$main-font: 'Montserrat', sans-serif;


:root{
    --color-white: #ffffff;
    --color-main: #013479;
    --color-accent: #fef100;
    --color-bg: #f2f2f2;

    --headerHeight: 155px;
}

$main-font-size: 16px;
$main-font-weight: 400;
$main-line-height: 1.2;

$color-white: #ffffff;
$color-main: #013479;
$color-accent: #fef100;
$color-grey: #f2f2f2;


// Transitions
$transition-base: 0.33s cubic-bezier(.23, 1, .32, 1);
$transition-slow: .6s cubic-bezier(0.08, 1.00, 0.21, 1.00);