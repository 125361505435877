.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    padding: em(52) 0;
    transition: padding .3s ease-in-out;
    @include r(991){
        padding: em(20) 0;
    }
    &__logo{
        display: flex;
        align-items: center;
        .logo{
            margin-right: em(27);
            @include r(991){
                width: 22vw;
            }
        }
        &-text{
            padding: 0 em(16);
            background-color: $color-accent;
            font-weight: 700;
            letter-spacing: 0.16px;
            @include r(991){
                padding: em(5) em(10);
                p{
                    margin: 0;
                }
            }
        }
    }
    &__nav{
        display: flex;
        align-items: center;

        .nav{
            @include nav;

            &__list {
                &-link {
                    text-transform: uppercase;
                }
            }
        }
        @include r(991) {
            justify-content: center;
            position: fixed;
            flex-direction: column;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: $color-white;
            transform: translateY(-100%);
            overflow: hidden;
            transition: transform .6s linear,
                opacity .4s linear;
            .nav__list{
                &-item {
                    margin-right: 0;
                    margin-bottom: em(30);
                    // transform: translateY(-50%);
                    // transition: transform .4s ease-in;
                    // &:first-child {
                    //     transition-delay: .3s;
                    // }
                    // &:nth-child(2) {
                    //     transition-delay: .4s;
                    // }
                    // &:last-child {
                    //     transition-delay: .5s;
                    // }
                }
                &-link{
                    font-size: 37px;
                    font-weight: 800;
                }
            }
            .menu-open & {
                opacity: 1;
                transform: translateY(0);
                .nav__list-item {
                    // transform: translateY(0);
                }
            }
        }
    }
    .lang-switch{
        position: relative;
        margin-left: em(22);
        text-align: right;
        overflow: hidden;
        z-index: 10;
        max-height: em(50);
        &__list{
            padding: 0 em(60) 0 em(35);
            display: flex;
            flex-direction: column;
            background-color: $color-main;
            position: relative;
            max-height: em(50);
            transition: max-height .3s ease-in-out;
        }
        &.is-open{
            overflow: visible;
            .lang-switch__list{
                max-height: 100%;
                @include r(991){
                    max-height: em(120);
                }
            }
            .lang-switch__arrow{
                transform: rotate(180deg) translateY(2px);
            }
        }
        @include r(991){
            margin-left: 0;
        }
        &__item{
            padding: em(16) 0;
            display: flex;
            order: 2;
            font-weight: 700;
            text-transform: uppercase;
            color: $color-white;
            text-decoration: none;
            &.is-active{
                order: 1;
                pointer-events: none;
            }
        }
        &__arrow{
            position: absolute;
            right: em(15);
            top: em(15);
            border: none;
            background: none;
            outline: none;
            // transition: transform .3s linear;
            svg{
                fill: $color-white;
                width: em(19);
                height: em(12);
            }
        }
    }

    @include rmin(992){
        body.scrolled & {
            padding: em(20) 0;
        }
    }
}
