.inner-page{
    padding: 50px var(--headerGap);
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;

    h3{
        font-size: 30px;
        @include r(575){
            font-size: 20px;
        }
        text-transform: none;
        margin-top: 30px;
    }

    p{
        font-size: 18px;
        @include r(575){
            font-size: 14px;
        }
    }
}
