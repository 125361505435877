.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.text-nowrap {
    white-space: nowrap;
}

.underline-text {
    background-image: linear-gradient(transparent 50%, #fff000 0);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.mob-hidden{
    @include r(991){
        display: none !important;
    }
}

.dsktp-hidden{
    @include rmin(992){
        display: none !important;
    }
}