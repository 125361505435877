.displays-page{
    @include rmin(992){
        background-color: $color-grey;
    }
    .header{
        @include rmin(992) {
            background-color: $color-grey;
        }
    }
    .intro{
        height: calc(100vh - var(--headerHeight));
        @include rIn(992, 1200) {
            height: calc(90vh - var(--headerHeight));
        }
        @include r(991){
            height: auto;
        }
        &__container{
            height: 100%;
            @include r(575){
                padding-left: em(45);
                padding-right: em(45);
            }
        }
        &__row{
            height: 100%;
            @include r(991){
                flex-direction: column;
            }
        }
        &__col{
            position: relative;
            &.--left{
                padding: 0 em(20) em(40) em(64);
                width: 35%;
                @include r(991){
                    width: 60%;
                    padding: em(40) 0;
                }
                @include r(575){
                    width: 100%;
                }
            }
            &.--right{
                width: 65%;
                @include r(991) {
                    background-color: $color-grey;
                    width: 100vw;
                }
            }
        }
        .h1{
            display: inline-block;
            font-size: vw(69px);
            margin-bottom: rem(40);
            @include r(991) {
                @include css-lock(37, 63, 575, 991);
                display: block;
                max-width: fit-content;
                padding: 0 em(10);
                margin-left: auto;
                margin-right: auto;
            }
            @include r(575){
                margin-bottom: em(20);
            }
        }
        &__hr{
            width: vw(77px);
            height: 2px;
            background-color: $color-main;
            margin: em(40) 0 em(20);
            padding: 0;
            border: none;
            @include r(991){
                width: em(77);
            }
        }
        &__text{
            &-bottom{
                font-weight: 500;
                line-height: 1.5;
                letter-spacing: 0.16px;
                @include rmin(992){
                    strong{
                        font-weight: 500;
                    }
                }
                @include r(991){
                    br{
                        display: none;
                    }
                    span{
                        display: block;
                        margin-top: em(20);
                    }
                }
            }
        }
        &__contact{
            list-style: none;
            padding: 0;
            &-item{
                margin-bottom: em(5);
                max-width: fit-content;
                position: relative;
                padding: 0 em(5);
                margin-left: em(-5);
                @include underlineYellow();
                &:hover:not(:last-child) {
                    @include underlineYellow(100%);
                }
                a{
                    position: relative;
                    z-index: 1;
                    text-decoration: none;
                    color: $color-main;
                }
            }
        }
        &__bottom{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: em(45) 0 em(10);
            svg{
                width: 17px;
                height: 11px;
            }
        }
        .carousel{
            padding-bottom: em(80);
            @include r(991){
                padding-top: em(70);
                padding-bottom: em(95);
                min-height: 93vh;
            }
            @media (max-width: 415px) and (min-height: 700px) and (max-height: 900px) {
                min-height: 80vh;
            }
            @include r(380){
                min-height: 60vh;
            }
            &__wrapper{
                align-items: center;
                height: vw(650px);
                @include r(991) {
                    height: auto;
                }
            }
            &__item{
                height: auto;
                &-wrap{
                    position: relative;
                    padding: em(25) em(15);
                    background: $color-white;
                    background:linear-gradient(-135deg, transparent 45px, $color-white 0);
                    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
                    width: vw(121px);
                    height: vw(237px);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    opacity: .6;
                    transition: all .5s ease-in;
                    @include r(1200) {
                        background: linear-gradient(-135deg, transparent 20px, $color-white 0);
                    }
                    @include r(991){
                        padding: rem(30);
                        width: 42.40208vw;
                        height: 80.8vw;
                        opacity: 1;
                        filter: drop-shadow(0 14px 10px rgba(0, 0, 0, .16));
                    }
                }
                &-image{
                    width: 100%;
                    position: relative;
                    padding-top: 170%;
                    img{
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: auto;
                        height: 100%;
                        object-fit: contain;
                        transform: translateX(-50%);
                        @include r(991){
                            // width: 100%;
                        }
                    }
                    transition: width .5s linear;
                    @include r(991){
                        opacity: .1;
                    }
                }
                &-content{
                    position: relative;
                    width: 0;
                    height: 100%;
                    padding-top: em(74);
                    transform: scale(0) translateX(15%);
                    opacity: 0;
                    transition: all .6s ease-in,
                        width 0s ease-in,
                        opacity .9s ease-in;
                    @include r(991){
                        padding-top: em(30);
                    }
                }
                &-label{
                    padding: em(7) em(13);
                    display: flex;
                    background-color: $color-main;
                    color: $color-white;
                    max-width: fit-content;
                    font-size: vw(14px);
                    font-weight: 500;
                    line-height: 1.2;
                    letter-spacing: 1.14px;
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    overflow: hidden;
                    @include r(991){
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 14px;
                        opacity: 0;
                        transition: opacity .6s ease-in;
                        svg{
                            margin-right: em(10) !important;
                        }
                    }
                    svg{
                        fill: none;
                    }
                }
                &.--flex-assortiment{
                    .carousel__item-label{
                        svg{
                            width: vw(19px);
                            height: vw(13px);
                            margin-right: vw(-14px);
                            @include r(991){
                                width: 19px;
                                height: 13px;
                            }
                        }
                    }
                    @include r(991){
                        &.swiper-slide-prev{
                            .carousel__item-image img{
                                left: 70%;
                            }
                        }
                        &.swiper-slide-next {
                            .carousel__item-image img {
                                left: 30%;
                            }
                        }
                    }
                }
                &.--fixed-assortiment {
                    .carousel__item-label {
                        position: relative;
                        span {
                            display: inline-block;
                            transition: transform .5s ease-in-out;
                        }
                        svg {
                            width: vw(21px);
                            height: vw(16px);
                            margin-right: vw(10px);
                            @include r(991){
                                width: 2.09375vw;
                                height: 2.3333vw;
                            }
                            @include r(575){
                                width: 5.09375vw;
                                height: 4.3333vw;
                            }
                        }
                        .hidden-text{
                            position: absolute;
                            left: vw(61px);
                            top: em(8);
                            transform: translateY(-140%);
                            z-index: 0;
                            transition: transform .5s ease-in-out;
                        }
                        @include rmin(992){
                            &:hover{
                                span{
                                    transform: translateY(140%);
                                }
                                .hidden-text{
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                    .carousel__item-content{
                        padding-left: em(15);
                    }
                }
                &-header{
                    position: relative;
                    margin-top: em(37);
                    width: fit-content;
                    font-weight: 800;
                    letter-spacing: 0.9px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    &:after{
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 65%;
                        height: 2px;
                        content: '';
                        background-color: $color-accent;
                    }
                }
                &.line-100{
                    .carousel__item-header:after {
                        width: 100%;
                    }
                }
                &-text{
                    font-weight: 500;
                    line-height: 1.5;
                    letter-spacing: 0.16px;
                }
                &-link{
                    position: absolute;
                    bottom: 0;
                    padding: em(4) em(15);
                    svg{
                        width: vw(14px);
                        height: vw(20px);
                        @include r(991){
                            width: 1.72917vw;
                            height: 2.04167vw;
                        }
                        @include r(575){
                            width: 3.72917vw;
                            height: 5.04167vw;
                        }
                    }
                    @include r(991){
                        width: 100%;
                        opacity: 0;
                        transition: all .6s ease-in;
                    }
                }
                &.swiper-slide-active {
                    width: auto !important;
                    margin-left: vw(-110px);
                    @include r(991){
                        margin-left: -17%;
                        .carousel__item-label{
                            opacity: 1;
                        }
                        .carousel__item-link {
                            opacity: 1;
                        }
                    }
                    &:first-child {
                        margin-left: -7.6%;
                    }
                    .carousel__item-wrap {
                        padding: em(65) em(45);
                        width: vw(617px);
                        height: vw(650px);
                        opacity: 1;
                        z-index: 2;
                        @include r(991){
                            width: 66.669vw;
                            height: auto;
                            max-height: 900px;
                            padding: em(50) em(30);
                            flex-direction: column;
                        }
                    }
                    @include rmin(992){
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: vw(-10px);
                            left: 50%;
                            width: 60%;
                            height: vw(10px);
                            transform: translateX(-50%);
                            background-color: $color-accent;
                            z-index: -1;
                        }
                    }
                    .carousel__item-image{
                        padding-top: 100%;
                        width: 45%;
                        @include r(991){
                            width: 100%;
                            opacity: 1;
                        }
                    }
                    .carousel__item-content{
                        width: 55%;
                        transform: scale(1) translateX(0);
                        opacity: 1;
                        @include r(991){
                            position: static;
                        }
                        @include r(575) {
                            width: 100%;
                        }
                    }
                }
                &.swiper-slide-prev{
                    margin-left: 30px;
                    .carousel__item-wrap{
                        background: linear-gradient(-45deg, transparent 25px, $color-white 0);
                        @include r(1200) {
                            background: linear-gradient(-45deg, transparent 10px, $color-white 0);
                        }
                        @include r(991) {
                            background: linear-gradient(-45deg, transparent 25px, $color-grey 0) !important;
                            left: -75%;
                        }
                    }
                    @include rmin(992){
                        display: flex;
                        justify-content: center;
                    }
                    @include rIn(992,1200){
                        margin-left: 10px;
                    }
                    @include r(991){
                        margin-left: 0;
                    }
                }
                &.swiper-slide-next {
                    .carousel__item-wrap{
                        background: linear-gradient(45deg, transparent 25px, $color-white 0);
                        @include r(1200) {
                            background: linear-gradient(45deg, transparent 10px, $color-white 0);
                        }
                        @include r(991) {
                            background: linear-gradient(45deg, transparent 25px, $color-grey 0) !important;
                            left: -2%;
                        }
                    }
                }
            }
            &__pagination{
                bottom: inherit;
                margin-top: 22px;
                padding: em(14) em(14) em(12);
                width: auto;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: em(50);
                background-color: $color-white;
                left: 56%;
                white-space: nowrap;
                transform: translateX(-50%);
                @include r(991){
                    padding: em(7) em(14);
                    left: 50%;
                }
                .swiper-pagination-bullet{
                    background-color: $color-grey;
                    width: vw(13px);
                    height: vw(13px);
                    margin-left: 0;
                    margin-right: em(23);
                    opacity: 1;
                    transition: all .3s linear;
                    @include r(991){
                        width: em(10);
                        height: em(10);
                        margin-right: em(15);
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    &-active{
                        background-color: $color-main;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                        transform: scale(1.2);
                    }
                    &:hover{
                        filter: contrast(0.5);
                    }
                }
            }
            &__button{
                padding: em(15) em(24);
                position: absolute;
                top: 45%;
                transform: translateY(-50%) translateX(0) scale(1);
                z-index: 10;
                cursor: pointer;
                opacity: 1;
                transition: all .3s linear;
                border-radius: 50%;
                @include r(991){
                    padding: em(19) em(24);
                }
                @include r(575){
                    padding: em(11) em(17) em(12);
                }
                &.--prev{
                    left: 15.7%;
                    @include r(1200) {
                        left: 14%;
                    }
                    @include r(991){
                        left: 5% !important;
                    }
                    svg{
                        right: 3px;
                        @include r(1200) {
                            right: 1px;
                        }
                    }
                    &.swiper-button-disabled{
                        transform: translateY(-50%) translateX(-15%) scale(0.15);
                    }
                }
                &.--next{
                    right: 5.5%;
                    @include r(1200) {
                        right: 3.5%;
                    }
                    @include r(991){
                        right: 5% !important;
                    }
                    svg{
                        left: 3px;
                        transform: rotate(180deg);
                        transition: all .3s linear;
                        @include r(1200) {
                            left: 1px;
                        }
                    }
                    &.swiper-button-disabled {
                        transform: translateY(-50%) translateX(15%) scale(0.15);
                    }
                }
                svg{
                    position: relative;
                    top: 2px;
                    width: vw(19px);
                    height: vw(32px);
                    @include r(991){
                        width: 1.98958vw;
                        height: 2.66667vw;
                    }
                    @include r(575){
                        width: 2.98958vw;
                        height: 3.66667vw;
                    }
                }
                &:hover{
                    background-color: $color-main;
                    color: $color-white;
                    transform: translateY(-50%) scale(1.15);
                }
                &.swiper-button-disabled{
                    opacity: 0;
                    transform: translateY(-50%) scale(0.15);
                }
            }
        }
    }
}
