.main-page{
    .intro{
        position: relative;
        width: 100%;
        &__image{
            position: absolute;
            left: 0;
            width: 100%;
            height: 100vh;
            @include rmin(992){
                top: 9%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include rIn(576, 991){
                    object-position: 30%;
                }
            }
        }
        &__container{
            height: calc(100vh - var(--headerHeight));
        }
        &__row{
            justify-content: center;
            align-items: center;
            height: 100%;
            @include r(991) {
                position: static;
            }
        }
        .h1{
            position: relative;
            z-index: 1;
            @include r(575){
                margin-bottom: 40%;
                br{
                    display: none;
                }
            }
        }
        &__bottom{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: fit-content;
            @include r(991) {
                left: 0;
                width: 100vw;
                transform: translateX(0);
                background-color: $color-white;
                flex-direction: column-reverse;
            }
            &-arrow{
                position: relative;
                padding: 0 em(40);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-main;
                @include r(991){
                    width: fit-content;
                    margin: auto;
                    padding: em(15) em(16) em(15);
                }
                &:before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                    content: '';
                    width: 100%;
                    height: 0;
                    background-color: $color-accent;
                    transition: all .5s ease-in-out;
                }
                svg{
                    position: relative;
                    z-index: 1;
                    fill: $color-white;
                    width: vw(47px);
                    height: vw(29px);
                    transition: transform .5s ease-in-out,
                        fill .3s linear;
                }
                &:hover{
                    &::before{
                        height: 100%;
                    }
                    svg{
                        transform: translateY(15%);
                        fill: $color-main;
                    }
                }
            }
            &-text{
                position: relative;
                padding: em(25) em(70);
                text-align: center;
                background-color: $color-white;
                color: $color-main;
                @include rmin(992){
                    &::after{
                        position: absolute;
                        content: '';
                        top: 0;
                        right: vw(-58.6px);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: vw(140px) 0 0 vw(60px);
                        border-color: transparent transparent transparent #ffffff;
                    }
                }
                @include r(575){
                    padding: em(15) em(5);
                }
                .font-1{
                    font-weight: 600;
                    letter-spacing: vw(1.35px);
                    text-transform: uppercase;
                    margin: 0;
                }
            }
        }
        .anchor{
            position: absolute;
            width: 100%;
            height: em(90);
            bottom: 0;
            z-index: -1;
        }
    }
    .tagline{
        position: relative;
        padding: em(75) 0;
        background-color: $color-main;
        color: $color-white;
        line-height: 1.5;
        @include r(575){
            padding: em(35) 0;
        }
        &__row{
            justify-content: center;
        }
        &__container{
            @include r(575){
                padding-left: em(60);
                padding-right: em(60);
            }
        }
        &__decor{
            position: absolute;
            &.--ladybag{
                top: vw(105px);
                left: vw(204px);
                width: vw(124px);
                padding-top: 7%;
                background: url(../../img/ladybug.png) no-repeat;
                background-size: cover;
                @include r(991){
                    width: 65px;
                    padding-top: 9%;
                }
                @include r(575){
                    top: 33%;
                    left: em(-15);
                    padding-top: 20%;
                }
            }
            &.--butterfly{
                z-index: 1;
                bottom: vw(-120px);
                right: vw(270px);
                width: vw(267px);
                padding-top: 14%;
                background: url(../../img/papillon.png) no-repeat;
                background-size: cover;
                @include r(991) {
                    width: 120px;
                    padding-top: 15%;
                    transform: rotate(-95deg);
                }
                @include r(575){
                    bottom: em(-70);
                    right: em(10);
                    padding-top: 30%;
                }
            }
        }
        .font-1{
            @include r(575){
                margin: 0;
                br{
                    display: none;
                }
            }
        }
    }
    .video{
        &__row{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        &__decor{
            width: vw(443px);
            height: vw(23px);
            background-color: $color-accent;
            @include r(575){
                width: 100%;
                height: em(15);
            }
        }
        &__block{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-main;
            margin: em(122) 0;
            width: vw(1226px);
            height: vw(690px);
            @include r(991){
                width: 100%;
                height: 350px;
                margin: em(60) 0;
            }
            @include r(575){
                height: 225px;
                margin: em(90) 0 em(60);
            }
            img{
                width: vw(126px);
                height: vw(144px);
            }

            video{
                width: 100%;
            }
        }
        &__hr{
            width: 100%;
            height: vw(1px);
            background-color: $color-main;
            margin: 0;
        }
    }
    .assortiment{
        padding: em(140px) 0 0;
        overflow: hidden;
        @include r(991){
            padding: em(50) 0;
        }
        &__row{
            align-items: flex-start;
            @include r(991){
                flex-direction: column-reverse;
                align-items: center;
            }
        }
        .h2{
            margin-left: vw(205px);
            @include r(991){
                display: block;
                margin: 0 auto;
                width: fit-content;
            }
            @include r(575){
                margin: 0;
            }
        }
        &__col{
            position: relative;
            &-decor{
                position: absolute;
                width: vw(756px);
                left: vw(205px);
                bottom: 0;
                padding-top: 20.5777%;
                @include r(991){
                    width: 333px;
                    padding-top: 57.5777%;
                    left: em(-50);
                    bottom: em(25);
                }
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    object-fit: cover;
                }
            }
            &.--left{
                width: 50%;
                padding-left: vw(205px);
                padding-bottom: em(210px);
                @include r(991){
                    padding-left: 0;
                    padding-bottom: 0;
                }
                @include r(575){
                    width: 100%;
                }
                .font-2{
                    @include rmin(992) {
                        max-width: 80%;
                    }
                    &:nth-child(2){
                        @include rmin(992){
                            max-width: 90%;
                        }
                        a{
                            position: relative;
                            z-index: 1;
                            color: inherit;
                            text-decoration: none;
                        }
                        b{
                            position: relative;
                            padding: 0 0.3125em;
                            max-width: fit-content;
                            &:before{
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 50%;
                                bottom: -10%;
                                left: 0;
                                background-color: #fef100;
                                transition: .6s cubic-bezier(.08, 1, .21, 1);
                                backface-visibility: hidden;
                                opacity: 1;
                                pointer-events: none;
                                z-index: 0;
                            }
                            &:hover{
                                &:before{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            &.--right{
                padding-top: em(170px);
                width: 50%;
                @include r(991){
                    width: 100%;
                    padding-top: em(50);
                }
            }
        }
        &__link{
            margin-top: em(52);
            @include r(991){
                margin: em(40) auto 0;
            }
        }
        &__image{
            position: relative;
            width: vw(939px);
            padding-top: 61%;
            @include r(991){
                width: 100%;
            }
            img{
                position: absolute;
                top: 0;
                right: vw(115px);
                object-fit: cover;
                width: 100%;
                @include r(991){
                    right: 0;
                }
                @include r(575){
                    left: em(-30);
                }
            }
        }
    }
    .about{
        background-color: $color-grey;
        padding: em(100) 0 em(82);
        @include r(991){
            padding: em(50) 0;
        }
        &__row{
            align-items: flex-start;
            @include r(991){
                flex-direction: column;
                align-items: center;
            }
        }
        &__col{
            position: relative;
            width: calc(100%/3);
            padding: 0 em(20) em(273);
            text-align: center;
            @include r(991){
                width: 100%;
                max-width: 340px;
                padding: 0 0 em(30);
                .text-center{
                    text-align: left;
                }
            }
            &-image{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                padding-top: 50%;
                @include r(991){
                    position: relative;
                    left: 0;
                    transform: translateX(0);
                    margin: 0 auto;
                }
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    object-fit: cover;
                }
            }

            &:first-child{
                .about__col-image{
                    width: vw(291px);
                    @include r(991) {
                        padding-top: 65%;
                        width: 229px;
                    }
                }
            }

            &:nth-child(2){
                @include rmin(992){
                    border-left: 2px solid $color-main;
                    border-right: 2px solid $color-main;
                }
                @include r(991) {
                    border-top: 2px solid $color-main;
                    border-bottom: 2px solid $color-main;
                    padding: em(30) 0;
                }
                .about__col-image {
                    width: vw(202px);
                    @include r(991) {
                        padding-top: 70%;
                        width: 177px;
                    }
                }
            }

            &:last-child{
                @include r(991) {
                    padding-top: em(30);
                }
                .about__col-image {
                    width: vw(303px);
                    padding-top: 20%;
                    bottom: 15%;
                    @include r(991) {
                        padding-top: 25%;
                        width: 229px;
                    }
                }
            }

            .font-2{
                margin-bottom: em(70);
            }
        }
    }
    .displays{
        padding: em(120) 0;
        @include r(991){
            padding: em(80) 0;
        }
        @include r(575){
            padding: em(40) 0;
        }
        .h2{
            @include r(575){
                br{
                    display: none;
                }
            }
        }
        &__row{
            flex-direction: column;
            justify-content: center;
        }
        &__link{
            font-size: vw(28px);
            @include r(991) {
                @include css-lock(14, 45, 375, 991);
            }
        }
    }
}